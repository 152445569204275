
import {
    CloudUploadOutlined
} from '@ant-design/icons-vue';

export default {
    components: {
        CloudUploadOutlined
    },
    emits: [
        'done',
        'update:visible'
    ],
    props: {
        // 是否打开弹窗
        visible: Boolean,
        data: Object
    },
    data() {
        return {
            // 导入请求状态
            loading: false,
            message_type: 'error',
            messages: []
        };
    },
    methods: {
        /* 上传 */
        doUpload(file) {
            this.loading = true;
            this.messages = [];

            let formData = new FormData();

            formData.append('file', file.file);

            this.$http.post(`/user/order/import`, formData).then(res => {
                this.loading = false;

                if (res.data.code === 0) {
                    this.messages = res.data.msg.split('\n').map(a => {
                        var str_array = a.split(',');

                        return this.$t(str_array[0], str_array.slice(1));
                    });
                    this.message_type = 'success';
                    this.message_visible = true;

                    // this.$message.success(res.data.msg);
                    // this.updateVisible(false);
                    this.$emit('done');
                } else {
                    this.messages = res.data.msg.split('\n').map(a => {
                        var str_array = a.split(',');

                        return this.$t(str_array[0], str_array.slice(1));
                    });
                    this.message_type = 'error';
                    this.message_visible = true;
                }
            }).catch(e => {
                this.loading = false;
                this.$message.error(e.message);
            });
            return false;
        },
        /* 更新visible */
        updateVisible(value) {
            this.$emit('update:visible', value);
        },
        /* 下载模板 */
        downloadTemplate() {
            this.$http.get("/user/order/download_import_template", {
                responseType: "blob"
            }).then(res => {
                const fileName = this.$t('订单模板.xlsx');

                if ("download" in document.createElement("a")) {
                    // 非IE下载
                    const el = document.createElement("a");

                    el.download = fileName;
                    el.style.display = "none";
                    el.href = URL.createObjectURL(res.data);
                    document.body.appendChild(el);

                    el.click();

                    URL.revokeObjectURL(el.href);
                    document.body.removeChild(el);
                } else {
                    // IE10+下载
                    navigator.msSaveBlob(res.data, fileName);
                }
            })
        }
    }
}
